<template>
  <div
    :class="[
      'container-' + organism.containerWidth,
      'organism brand-window-text-overlay bg-center bg-no-repeat bg-cover flex justify-center items-center py-4 sm:py-14 lg:py-28 px-2 sm:px-8 h-[550px] sm:h-[623px] relative overflow-hidden',
      organism.brandWindowLayout,
    ]"
    :style="[
      organism?.backgroundImage
        ? 'background-image: url(' +
          getImageSrc(organism?.backgroundImage) +
          ');'
        : '',
      organism.border
        ? 'outline: ' +
          organism.borderWidth +
          'px solid ' +
          organism.borderColor +
          '; outline-offset: -' +
          organism.borderInset +
          'px;'
        : '',
    ]"
  >
    <template v-if="organism?.backgroundVideoFile">
      <div class="video-container cursor-default w-full h-full absolute -z-1">
        <video
          class="absolute top-0 left-0 w-full h-full pointer-events-none object-cover"
          :src="organism?.backgroundVideoFile"
          autoplay
          muted
          loop
          playsinline
        ></video>
      </div>
    </template>

    <div
      v-if="organism.customBackgroundOverlayColor"
      class="absolute inset-0 overlay"
      :style="'background-color: ' + organism.backgroundOverlayColor + ';'"
    ></div>
    <div
      v-if="!organism.customBackgroundOverlayColor"
      class="absolute inset-0 overlay bg-gradient-to-b from-black/20 to-black/50"
    ></div>
    <div class="z-10 w-full mx-auto text-white max-w-7xl">
      <div
        :class="[
          organism.brandWindowLayout === 'text-overlay-left'
            ? 'text-left'
            : organism.brandWindowLayout === 'text-overlay-center'
              ? 'text-center mx-auto'
              : '',
          'flex flex-col justify-center items-center w-full md:w-3/4 xl:w-1/2 px-8 py-4 sm:py-12 sm:px-10 content',
        ]"
      >
        <AtomsCollectionButton
          v-if="organism.collection"
          :collection="organism.collection"
        />
        <h3
          v-if="organism.title3"
          class="font-figtree text-[17px] font-bold uppercase drop-shadow-text"
        >
          {{ organism.title3 }}
        </h3>
        <div v-if="organism.title1">
          <h2
            v-if="organism.primaryHeadingLevel === 'h2'"
            class="mb-4 text-4xl font-semibold sm:text-6xl font-figtree drop-shadow-text"
          >
            <span class="block">{{ organism.title1 }}</span>
          </h2>
          <h1
            v-else
            class="text-4xl font-semibold sm:text-6xl font-figtree drop-shadow-text"
          >
            <span class="block">{{ organism.title1 }}</span>
          </h1>
        </div>
        <h2
          v-if="organism.title2"
          class="text-4xl font-semibold drop-shadow-text sm:text-6xl font-figtree"
        >
          {{ organism.title2 }}
        </h2>
        <div
          v-if="organism.description"
          class="max-w-xl my-4 text-base content md:text-xl"
          v-html="organism.description"
        ></div>
        <div v-if="organism.button1" class="pb-4"></div>
        <MoleculesButtons
          :class="[
            organism.brandWindowLayout === 'text-overlay-left'
              ? '!justify-start'
              : '',
            organism.brandWindowLayout === 'text-overlay-center'
              ? '!justify-center'
              : '',
          ]"
          :buttons="organism"
          :button-colors="buttonColors"
        />
      </div>
    </div>
  </div>
</template>

<style>
.brand-window-text-overlay {
  &::before {
    content: "";
  }

  .buttons {
    a {
      @apply py-3 uppercase font-figtree font-medium text-[15px];
    }
  }
}
.organism-slider {
  .brand-window-text-overlay > div > .content {
    @apply px-10 2xl:px-0;
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});

const buttonColors = computed(() => {
  const buttonColorsArray = [];
  if (props.organism.button1Color) {
    buttonColorsArray.push(props.organism.button1Color);
  }
  if (props.organism.button2Color) {
    buttonColorsArray.push(props.organism.button2Color);
  }
  if (props.organism.button3Color) {
    buttonColorsArray.push(props.organism.button3Color);
  }
  return buttonColorsArray;
});
</script>
